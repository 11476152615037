<template>
  <div>
    <v-progress-linear
      indeterminate
      color="red"
      v-if="loading"
    ></v-progress-linear>
    <v-sheet height="600">
      <v-sheet tile height="54" class="d-flex">
        <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-2" v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon class="ma-2" @click="$refs.calendar.next()">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-sheet>
      <v-calendar
        locale="pt"
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @change="getSchedules"
        @click:event="showEvent"
        :event-more="false"
      ></v-calendar>
    </v-sheet>
  </div>
</template>

<script>
import http from "@/modules/http";
export default {
  mounted() {
    this.getSchedules();
  },
  data: () => ({
    loading: false,
    type: "month",
    types: ["month", "week", "day", "4day"],
    mode: "stack",
    modes: ["stack", "column"],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      { text: "Mon, Wed, Fri", value: [1, 3, 5] },
    ],
    value: "",
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
  }),
  methods: {
    showEvent(e) {
      this.$router.push('/editar-cte/'+e.event.cte);
    },
    getSchedules() {
      this.loading = true;
      http
        .get("api/schedules")
        .then((res) => {
          this.loading = false;
          let data = [];
          let itemData = null;
          res.data.forEach((el) => {
            itemData = {
              id: el.id,
              cte: el.cte_id,
              name: el.ctes.recipients.name,
              start: el.date + " " + el.time,
              //color: this.colors[this.rnd(0, this.colors.length - 1)],
              color: "grey",
              responsible: el.responsible,
            };
            data.push(itemData);
          });

          this.events = data;
        })
        .catch((err) => {
          this.loading = false;
          this.$side({
            type: "error",
            msg: "Erro ao buscar",
            duration: 4000,
          });
          console.log(err);
        });
    },
    getEvents({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        });
      }

      this.events = events;
    },
    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
};
</script>