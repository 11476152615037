<template>
  <div>
    <v-icon
      @click="addScheduling(cte)"
      :title="
        cte.schedules.length > 0
          ? `Dia ${formatDate(cte.schedules[0].date)} às ${
              cte.schedules[0].time
            }`
          : 'Adicionar Agendamento'
      "
      :class="
        cte.schedules.length > 0 && cte.schedules[0].date == today
          ? 'blink'
          : ''
      "
    >
      {{ setScheduleIcon(cte) }}
    </v-icon>
  
    <div v-if="isAddingScheduling">
      <v-dialog v-model="isAddingScheduling">
      
        <v-card>
          <v-card-title>
            Adicionar Agendamento
          </v-card-title>
          <v-card-text v-if="cte">
            <p>CT-e: {{ cte.number }} para cliente {{ cte.recipients.name }} agendado para o dia <strong>{{ formatedDate }}</strong> as <strong>{{ schedule.time }}</strong>.</p>
            <p>Responsável pelo agendamento: <strong>{{ schedule.responsible ? schedule.responsible : 'Não informado' }}</strong></p>
            <v-row>
              <v-col cols="12" md="8">
                <v-text-field
                  label="Responsável"
                  v-model="schedule.responsible"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn
                  color="secondary"
                  @click="save()"
                  class="mb-2 mt-3"
                  block
                  :loading="saving"
                >Salvar</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-date-picker 
                  v-model="schedule.date"
                  locale="pt"
                  full-width
                ></v-date-picker>
              </v-col>
              <v-col cols="12" md="6">
                <v-time-picker
                  v-model="schedule.time"
                  class="mt-4"
                  format="24hr"
                  full-width
                  landscape
                ></v-time-picker>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <schedules-calendar ref="schedulesCalendar" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

      </v-dialog>
    </div>
  </div>
</template>

<script>
import http from '@/modules/http'
import moment from 'moment'
import SchedulesCalendar from './SchedulesCalendar.vue'
  export default {
    computed: {
      formatedDate() {
        if(this.schedule.date) {
          return moment(this.schedule.date).format("DD/MM/YYYY")
        } else {
          return ''
        }
      }
    },
    components: {
      SchedulesCalendar,
    },
    props: {
      cte: {
        type: Object,
        default: null
      },
    },
    data() {
      return {
        today: new Date().toISOString().slice(0, 10),
        isAddingScheduling: false,
        saving: false,
        schedule: {
          cte_id: this.cte.id,
          date: null,
          time: null,
          responsible: ''
        }
      }
    },
    methods: {
      formatDate(value, type) {
        return moment(value).format("DD/MM/YYYY");
      },
      addScheduling(item) {
        if(item.schedules.length > 0) {
          this.$router.push('/editar-cte/'+ item.id);
          return
        }
        
        this.scheduling = item;
        this.isAddingScheduling = true;
      },
      setScheduleIcon(cte) {
        if (cte.schedules.length > 0) {
          if (cte.schedules[0].date <= this.today) {
            return "mdi-clock-alert-outline";
          } else {
            return "mdi-clock-check-outline";
          }
        } else {
          return "mdi-clock-outline";
        }
      },
      save() {
        this.saving = true
        http.post('api/schedules', this.schedule)
        .then(res => {
          this.saving = false
          this.$refs.schedulesCalendar.getSchedules()
          this.$side({
            type    : 'success',
            msg     : 'Salvo com sucesso',
            duration: 4000
          })
          this.$emit('schedulingSaved', true)
          this.isAddingScheduling = false;
          this.schedule = {
            cte_id: this.cte.id,
            date: null,
            time: null,
            responsible: ''
          }
        }).catch(err => {
          this.saving = false
          console.log(err)
        })
      }
    },
  }
</script>

<style scoped>

</style>