<template>
  <div>
    <v-card>
      <v-card-title>
        Adicionar ocorrência | CTe: {{ cte.number }}
      </v-card-title>
      <v-card-text>
        <v-form ref="formAddOccurrence" @submit.prevent="save">
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                :items="ocTypes"
                item-text="name"
                item-value="id"
                v-model="occurrence.occurrence_type_id"
                label="Tipo de ocorrência"
                :loading="loading"
                :rules="requiredRules"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field 
                type="date"
                v-model="occurrence.date"
                label="Data"
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="occurrence.description"
                label="Descrição"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn :loading="saving" type="submit" color="success">Salvar</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import http from "@/modules/http"
  export default {
    props: {
      cte: {
        type: Object,
        default: null
      },
    },
    data() {
      return {
        loading: false,
        saving: false,
        ocTypes: [],
        occurrence: {
          cte_id: '',
          occurrence_type_id: '',
          date: new Date().toISOString().slice(0,10),
          description: ''
        },
        requiredRules: [
          v => !!v||'Este campo é obrigatório'
        ]
      }
    },
    methods: {
      cleanForm() {
        this.occurrence = {
          cte_id: '',
          occurrence_type_id: '',
          date: new Date().toISOString().slice(0,10),
          description: ''
        }
      },
      getOcTypes() {
        this.loading = true;
        http.get('api/occurrence-types')
        .then(res => {
          this.loading = false;
          this.ocTypes = res.data
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
          this.$side({
            type    : 'error',
            msg     : 'Erro ao buscar tipos de ocorrência',
            duration: 4000
          })
        })
      },
      save() {
        if(this.$refs.formAddOccurrence.validate()) {
          this.saving = true;
          http
            .post('api/occurrences', this.occurrence)
            .then(() => {
              this.saving = false;
              this.$side({
                type    : 'success',
                msg     : 'Salvo com sucesso',
                duration: 4000
              });
              this.$emit('occurrenceSaved');
            }).catch(err => {
              this.saving = false;
              console.log(err);
              this.$side({
                type    : 'error',
                msg     : 'Erro ao salvar ocorrência',
                duration: 4000
              });
            });
        }
      }
    },
    mounted () {
      this.getOcTypes();
      this.occurrence.cte_id = this.cte.id;
    },
  }
</script>

<style scoped>

</style>