<template>
  <div>
    <v-icon
      @click="print(false)"
      class="mx-2"
      title="Imprimir Ordem de Paletização"
      >mdi-printer</v-icon
    >
    <v-icon
      @click="print(true)"
      class="mx-2"
      title="Baixar Ordem de Paletização"
      >mdi-download</v-icon
    >
    <v-icon
      v-if="showEditOption"
      @click="updatingOrder = true"
      title="Editar Ordem de Paletização"
      color="warning"
      class="mx-2"
      >mdi-pencil</v-icon
    >

    <div v-if="updatingOrder">
      <v-dialog v-model="updatingOrder">
        <v-card>
          <v-card-title>Editar Paletização</v-card-title>
          <v-card-text>
            <v-form :disabled="saving" @submit.prevent="updatePalletization">
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="Quant. Paletes"
                    v-model="palletization.qty"
                    @input="calcPalletization"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="Total"
                    :disabled="true"
                    v-model="palletization.value"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn :loading="saving" color="success" type="submit"
                    >Salvar</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import http from "@/modules/http";
import { jsPDF } from "jspdf";
import logo from "../../../assets/logo_sem_fundo.png";
// import signature from "../../../assets/signature.png";
export default {
  props: {
    cte: {
      type: Object,
      default: null,
    },
    showEditOption: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      updatingOrder: false,
      palletization: null,
      saving: false,
    };
  },
  mounted() {
    this.palletization = this.cte.palletizations[0];
  },
  methods: {
    calcPalletization() {
      let total = this.palletization.qty * 35;
      this.palletization.value = total;
    },
    updatePalletization() {
      if (this.palletization.qty < 1 || this.palletization.value < 1) {
        this.$toast.error("Valores inválidos");
      } else {
        http
          .put(
            "api/palletizations/" + this.palletization.id,
            this.palletization
          )
          .then(() => {
            this.updatingOrder = false;
            this.$toast.success("Salvo com sucesso");
            this.$emit("update");
          })
          .catch((err) => {
            console.log(err);
            this.$toast.error("Erro ao salvar");
            this.updatingOrder = false;
            this.$emit("update");
          });
      }
    },
    fCnpj(value) {
      if (value) {
        if (value.length === 14) {
          return value.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
            "$1.$2.$3/$4-$5"
          );
        } else {
          return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        }
      } else {
        return "Vazio";
      }
    },
    print(download) {
      const doc = new jsPDF({ orientation: "portrait" });

      doc.addImage(logo, "JPEG", 70, -5, 80, 80);

      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text("ORDEM DE PALETIZAÇÃO E SEPARAÇÃO DE MERCADORIAS", 20, 60);

      doc.setFontSize(14);
      doc.setFont("helvetica", "normal");
      doc.text(`Fornecedor: ${this.cte.senders.name}`, 20, 80);

      doc.text(`Destinatário: ${this.cte.recipients.name}`, 20, 90);
      doc.text(`CNPJ: ${this.fCnpj(this.cte.recipients.cnpj)}`, 20, 100);
      doc.text(`Nota Fiscal: ${this.cte.nf}`, 20, 110);
      doc.text(`CTe: ${this.cte.number}`, 20, 120);
      doc.text(`Volumes: ${this.cte.qty}`, 20, 130);
      doc.text(
        `Peso: ${
          this.cte.weigth
            ? this.cte.weigth.toFixed(2).toString().replace(".", ",")
            : ""
        } kg`,
        20,
        140
      );

      doc.setFont("helvetica", "bold");
      doc.text(
        `Quant. Paletes: ${
          this.cte.palletizations[0].qty
        } X R$ 35,00 Cada, Total: R$ ${this.cte.palletizations[0].value.replace(
          ".",
          ","
        )}`,
        20,
        160
      );
      doc.line(20, 163, 180, 163);

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text("CARLOS NAZARENO CARDOSO TRANSPORTES DE CARGAS EIRELI", 20, 200);
      doc.text("CNPJ: 06.998.691/0001-40", 20, 205);
      doc.text("IE: 256501386", 90, 205);
      doc.text("NOME FANTASIA: LATINA TRANSPORTES", 20, 210);
      doc.text("ASS: _____________________________________________", 20, 215);

      doc.text(
        `Data: ${moment(this.cte.palletizations[0].date).format("DD/MM/YYYY")}`,
        160,
        280
      );

      // doc.addImage(signature, 150, 70, 70, 70, null, null, 250);

      if (download) {
        doc.save(`paletizacao-nf-${this.cte.nf}`);
        return;
      }

      doc.setProperties({
        title: `paletizacao-nf-${this.cte.nf}`,
      });
      doc.output("dataurlnewwindow");
    },
  },
};
</script>

<style scoped>
</style>